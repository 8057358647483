import React from 'react'
import Plaatjie from '@ubo/plaatjie'
import Img from 'gatsby-image'

// Images
// import Pin from 'img/icon/pin.inline.svg'
import pin from 'img/icon/pin.svg'
// import Phone from 'img/icon/phone.inline.svg'
import phone from 'img/icon/phone.svg'
// import At from 'img/icon/at.inline.svg'
import at from 'img/icon/at.svg'
// import WhitePhone from 'img/icon/white_phone.inline.svg'
import whitePhone from 'img/icon/white_phone.svg'

// Components
import ButtonDefault from 'components/elements/ButtonDefault'
import CustomLink from 'components/shared/CustomLink'

// Third Party
import parse from 'html-react-parser'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

const ImgCSS = css`
  position: absolute !important;
  height: 100%;
  pointer-events: none;

  ${(props) =>
    props.left &&
    css`
      right: 0;
    `}

  ${(props) =>
    props.article
      ? css`
          width: 100%;
        `
      : css`
          width: 70%;
        `}

  @media screen and (max-width: 991px) {
    width: 100%;
    position: relative !important;

    height: 250px;
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  ${ImgCSS};

  overflow: hidden;
  display: inline-block;
  vertical-align: top;

  & picture img {
    width: 100%;
    object-fit: cover;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
  }
`

const StyledImg = styled(Img)`
  ${ImgCSS};
`

const StyledHero = styled.div`
  position: relative;
  z-index: 1;

  ${(props) =>
    props.article
      ? css`
          ${props.small
            ? css`
                height: 350px;
              `
            : css`
                height: 360px;
              `}
        `
      : css`
          ${props.small
            ? css`
                height: 350px;
              `
            : css`
                height: 550px;
              `}
        `}

  @media screen and (max-width: 991px) {
    height: unset;
  }
`

const HeroContent = styled(motion.div)`
  background-color: ${(props) => props.theme.color.face.light};
  height: 100%;

  position: relative;

  & strong {
    color: ${({ theme }) => theme.color.face.main};
    margin-top: -25px;
    display: block;
    margin-bottom: 30px;
    font-weight: 800;
  }

  ${(props) =>
    props.left
      ? css`
          right: 100px;
          border-top-right-radius: 600px;
          border-bottom-right-radius: 600px;
          padding: 0 70px 0 0;
        `
      : css`
          left: 100px;
          border-top-left-radius: 600px;
          border-bottom-left-radius: 600px;
          padding: 0 0 0 70px;
        `}

  @media screen and (max-width: 991px) {
    border-radius: 20px;
    padding: 40px 40px 40px 40px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  }

  h1 {
    font-weight: ${(props) => props.theme.font.weight.xl};
    padding-bottom: 20px;

    @media screen and (max-width: 991px) {
      font-size: ${(props) => props.theme.font.size.xl};
    }
  }

  p {
    font-size: ${(props) => props.theme.font.size.m};
  }
`

const StyledButtonDefault = styled(ButtonDefault)`
  margin-top: 25px;

  @media screen and (max-width: 991px) {
    margin-top: 20px;
  }
`

const Line = styled.div`
  ${(props) =>
    props.thicc
      ? css`
          min-height: 63px;
        `
      : css`
          min-height: 10px;
        `}
  background: ${(props) => props.theme.color.gradient.main};
`

const Container = styled.div`
  height: 100%;

  @media screen and (max-width: 991px) {
    height: unset;
    position: relative;

    ${(props) =>
      props.thicc
        ? css`
            top: -90px;
            margin-bottom: -75px;
            padding: 0px 45px 0px 45px;
          `
        : css`
            ${props.article
              ? css`
                  top: unset;
                  bottom: 124px;
                  margin-bottom: -100px;
                  padding: 0px 30px 0px 30px;
                `
              : css`
                  top: -60px;
                  margin-bottom: -45px;
                  padding: 0px 45px 0px 45px;
                `}
          `}
  }
`

const WhiteStripe = styled(motion.div)`
  background-color: ${(props) => props.theme.color.face.light};
  min-height: 124px;
  border-top-right-radius: 600px;
  border-bottom-right-radius: 600px;
  padding: 20px 0 20px 0;
  position: relative;
  right: 50px;
  bottom: -10px;

  h1 {
    font-size: ${(props) => props.theme.font.size.xml};
    font-weight: ${(props) => props.theme.font.weight.xl};
    margin-bottom: 0;

    @media screen and (max-width: 991px) {
      font-size: ${(props) => props.theme.font.size.xl};
    }
  }

  p {
    font-size: ${(props) => props.theme.font.size.m};
  }

  &::before {
    content: '';
    position: absolute;
    height: 100%;
    background-color: ${(props) => props.theme.color.face.light};
    width: 2000px;
    left: -2000px;
    bottom: 0;
  }
`

const BackButton = styled(CustomLink)`
  position: absolute;
  top: -40px;

  p {
    font-size: ${(props) => props.theme.font.size.xm};
    font-weight: ${(props) => props.theme.font.weight.xl};
    color: ${(props) => props.theme.color.text.light};
    text-shadow: 2px 2px 4px ${(props) => props.theme.color.text.main};
  }

  &:hover {
    text-decoration: none;
  }
`

const ContactEntry = styled.div`
  p {
    margin-bottom: 0;
  }
`

const ContactLine = styled.div`
  h2 {
    color: ${(props) => props.theme.color.text.light};
    font-weight: ${(props) => props.theme.font.weight.xl};
    font-size: ${(props) => props.theme.font.size.lx};
    margin-bottom: 0;
  }

  h3 {
    color: ${(props) => props.theme.color.text.light};
    font-size: ${(props) => props.theme.font.size.l};
    margin-bottom: 0;
  }
`

const Hero = ({
  className,
  contact,
  small,
  left,
  title,
  content,
  backUrl,
  article,
  thicc,
}) => {
  return (
    <StyledHero
      small={small}
      article={article}
      className={`${className ? `${className}` : ``}`}
    >
      {!!content.image?.localFile?.childImageSharp?.fluid ? (
        <StyledImg
          thicc={thicc}
          left={left}
          article={article}
          fluid={content.image.localFile.childImageSharp.fluid}
          loading="eager"
          alt=""
        />
      ) : (
        <StyledPlaatjie
          thicc={thicc}
          left={left}
          article={article}
          image={content.image}
          loading="eager"
          alt=""
        />
      )}
      <Line className="d-block d-lg-none" thicc={thicc}>
        {/* {contact && (
          <ContactLine className="container d-flex align-items-center py-4">
            <WhitePhone className="mr-4" />
            <div>
              {parse(contact.speed)}
            </div>
          </ContactLine>
        )} */}
      </Line>
      <Container article={article} thicc={thicc} className="container">
        {!article ? (
          <div className="row h-100">
            {!left && <div className="col-lg-5" />}
            <HeroContent
              left={left}
              animate={!left ? { left: 0 } : { right: 0 }}
              transition={{ duration: 0.6, type: 'spring', damping: 15 }}
              className="col-lg-7 d-flex justify-content-center align-items-center flex-wrap"
            >
              <div className="pl-lg-5">
                {parse(content.description)}
                {contact && (
                  <div>
                    <ContactEntry className="pb-4 d-flex align-items-center">
                      {/* <Pin className="mr-2" /> */}
                      <img className="mr-2" src={pin} alt="" />
                      <div>{parse(contact.address_1)}</div>
                    </ContactEntry>
                    <ContactEntry className="pb-4 d-flex align-items-center">
                      {/* <Phone className="mr-2" /> */}
                      <img className="mr-2" src={phone} alt="" />
                      <div>{parse(contact.phone)}</div>
                    </ContactEntry>
                    <ContactEntry className="pb-4 d-flex align-items-center">
                      {/* <At className="mr-2" /> */}
                      <img className="mr-2" src={at} alt="" />
                      <div>{parse(contact.email)}</div>
                    </ContactEntry>
                    <ContactEntry className="pb-1 d-flex align-items-center">
                      <p>{`BTW nr. ${contact.btw}`}</p>
                    </ContactEntry>
                    <ContactEntry className="pb-1 d-flex align-items-center">
                      <p>{`KvK ${contact.kvk}`}</p>
                    </ContactEntry>
                    <ContactEntry className="py-3 d-flex align-items-center justify-content-end">
                      <ButtonDefault isAnchor to="tel:0854859383">
                        <p className="mb-0 font-weight-xl">BEL ONS</p>
                      </ButtonDefault>
                    </ContactEntry>
                  </div>
                )}
                {content.link && (
                  <StyledButtonDefault icon="arrow" to={content.link.url}>
                    {content.link.title}
                  </StyledButtonDefault>
                )}
              </div>
            </HeroContent>
            {left && <div className="col-lg-5" />}
          </div>
        ) : (
          <div className="row h-100 align-items-end">
            <WhiteStripe
              animate={{ right: 0 }}
              transition={{ duration: 0.6, type: 'spring' }}
              className="col-lg-6 d-flex justify-content-start align-items-center flex-wrap"
            >
              <BackButton to={backUrl}>
                <p>{`< Terug naar het overzicht`}</p>
              </BackButton>
              <h1>{title}</h1>
            </WhiteStripe>
            <div className="col-lg-6" />
          </div>
        )}
      </Container>
      <Line className="d-lg-block d-none" thicc={thicc}>
        {contact && (
          <ContactLine className="container d-flex align-items-center py-4">
            {/* <WhitePhone className="mr-4" /> */}
            <img className="mr-4" src={whitePhone} alt="" />
            <div>{parse(contact.speed)}</div>
          </ContactLine>
        )}
      </Line>
    </StyledHero>
  )
}

export default Hero
